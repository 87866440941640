<template>
  <div>
    <!--------------------- loading --------------------->
    <div v-if="isLoading" key="loading" class="text-center loading-container">
      <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
      <p class="mt-3">Loading notifications...</p>
    </div>
    <!--------------------- error --------------------->
    <div v-if="showError" key="error" class="jcard-content text-center">
      <h5 class="mb-3"><i class="fad fa-exclamation-circle text-blue fa-3x"></i><br /></h5>
      <p>
        Unable to fetch notifications from our servers at the moment.
      </p>
    </div>
    <!--------------------- section --------------------->
    <section v-if="!isLoading && !showError" key="content" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div><h3><small><i class="fal fa-bell"></i></small> Notifications List</h3></div>

        <!-- FILTERS -->
        <div class="d-flex mb-3">
          <div>
            <i class="fal fa-filter"></i>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(0)">
              All
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(1)">
              Users
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(2)">
              Watches
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(3)">
              Purchase
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(4)">
              Service
            </button>
          </div>
          <button @click="markAllAsRead()" class="btn btn-primary ml-auto" to="create-user">
            <i class="fa fa-bell-slash"></i> All
          </button>
        </div>
        <!-- TABLE -->
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th scope="col">Category</th>
              <th scope="col">Notification</th>
              <th scope="col">Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(appointment, index) in notificationsList" :key="appointment.id">
              <td><button v-if="appointment.isRead" key="read" type="button" class="jbtn-text" @click="updateNotificationStatus(appointment.id, false)"><i class="fal fa-bell" ></i>
            </button>
                <button v-else key="unread" type="button" class="jbtn-text" @click="updateNotificationStatus(appointment.id, true)">
                <i class="fa fa-bell"></i></button>
                
              </td>
              <td>
                <small>{{ appointment.id.substring(0, 6) }}</small>
              </td>
              <td>{{ appointment.category }}</td>
              <td>{{ appointment.text }}</td>

              <td>
                {{ $moment($C.getDateFromTimestamp(appointment.date)).format("DD-MM-YYYY hh:mm") }}
              </td>
              <td>
                <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto" no-caret>
                  <template #button-content>
                    <i class="fa fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item @click="showEditServiceDialog(index)"><i class="fal fa-clock mr-2"></i> Change Date </b-dropdown-item>
                  <b-dropdown-item @click="deleteService(index)"><i class="fal fa-trash mr-2"></i> Delete appointment</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "AppointmentsList",
  data() {
    return {
      //init
      isLoading: true,
      showError: false,
      isEmpty: false,
      dialogIsLoading: true,

      //dialog form

      showInputError: false,
      validationMsg: "",
      isFinalStep: false,

      //form button
      isSaving: false,
      isDone: false,

      //lists
      notificationsList: [],
      servicesList: new Map(),
      usersList: new Map(),
      watchesList: [],

      //edit data
      createdOn: serverTimestamp,
      appointmentDate: new Date(),
      userId: "",
      watchId: "",
      serviceId: "",
      status: 0,

      //flags
      asyncFlags: [false, false, false],
      filters: [false, false, false, false, false],
      activeFilter: 0
    };
  },
  methods: {
    //not used
    allNotifications: function() {
      this.isLoading = true;
      db.collection("appointments").onSnapshot(querySnapshot => {
        this.notificationsList.splice(0);
        querySnapshot.forEach(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.notificationsList.push(docdata);
        });
        this.asyncFlags[0] = true;
        if (this.asyncFlags[1] && this.asyncFlags[2]) this.isLoading = false;
      });
      // .catch((error) => {
      //   this.isLoading = false;
      //   this.showError = true;
      //   console.log("Error getting appointments: ", error);
      // });
    },
    allServices: function() {
      this.isLoading = true;
      db.collection("serviceCatalogue")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.servicesList.set(doc.id, docdata);
          });

          this.asyncFlags[1] = true;
          if (this.asyncFlags[0] && this.asyncFlags[2]) this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.showError = true;
          console.log("Error getting services: ", error);
        });
    },
    allUsers: function() {
      //todo: set hasAppoitment in user, to filter users here
      this.isLoading = true;
      db.collection("users")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.usersList.set(doc.id, docdata);
          });
          this.asyncFlags[2] = true;
          if (this.asyncFlags[0] && this.asyncFlags[1]) this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.showError = true;
          console.log("Error getting users: ", error);
        });
    },
    userWatches: function(id) {
      //todo: set hasAppoitment in user, to filter users here
      this.dialogIsLoading = true;
      db.collection("watches")
        .where("userId", "==", id)
        .get()
        .then(querySnapshot => {
          this.watchesList.splice(0);
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.watchesList.push(docdata);
          });
          this.dialogIsLoading = false;
        })
        .catch(error => {
          this.dialogIsLoading = false;
          this.showError = true;
          console.log("Error getting users: ", error);
        });
    },
    filterQuery: function(filter) {
      //if (this.filters[filter]) return;
      this.isLoading = true;
      var ref = db.collection("history");
      // all
      if (filter == 0) {
        this.changeActiveFilter(0);
      }
      // pending
      if (filter == 1) {
        ref = ref.where("status", "in", [0, 5]);
        this.changeActiveFilter(1);
      }
      // approved
      if (filter == 2) {
        ref = ref.where("status", ">=", 1).where("status", "<=", 2);
        this.changeActiveFilter(2);
      }
      // completed
      if (filter == 3) {
        ref = ref.where("status", "==", 3);
        this.changeActiveFilter(3);
      }
      // cancelled
      if (filter == 4) {
        ref = ref.where("status", "==", 4);
        this.changeActiveFilter(4);
      }
      ref.get().then(querySnapshot => {
        this.notificationsList.splice(0);
        querySnapshot.forEach(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.notificationsList.push(docdata);
        });
        this.asyncFlags[0] = true;
        if (this.asyncFlags[1] && this.asyncFlags[2]) this.isLoading = false;
      });
      // .catch((error) => {
      //   this.isLoading = false;
      //   this.showError = true;
      //   console.log("Error getting appointments: ", error);
      // });
    },
    changeActiveFilter(clicked) {
      this.$set(this.filters, this.activeFilter, false);
      this.$set(this.filters, clicked, true);
      this.activeFilter = clicked;
    },

    //mark notification as read/unread
    updateNotificationStatus(id, status) {
      this.isSaving = true;
      db.collection("history")
        .doc(id)
        .set(
          {
            readOn: serverTimestamp,
            isRead: status
          },
          { merge: true }
        )
        .then(() => {
          this.isSaving = false;
          this.filterQuery(this.activeFilter);
          toast.success("Notification status changed.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't change notification status. " + error.message);
        });
    },
    markAllAsRead(){
      //fun stuff goes here
    },
  },
  mounted() {
    this.filterQuery(0);
    this.allServices();
    this.allUsers();
  }
};
</script>

<style scoped></style>
